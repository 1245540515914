import {Spinner} from 'reactstrap';
import React, {useState, useEffect} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import axios from 'axios';

function App() {
  const baseUrl="https://apidc.duarteceramics.com/";
  const [data, setData]=useState([]);
  const [dataDetalle, setDataDetalle]=useState([]);
  const [modalInsertar, setModalInsertar]= useState(false);
  const [modalInsertarHabitaciones, setModalHabitaciones]= useState(false);
  const [modalEditar, setModalEditar]= useState(false);
  const [modalEliminar, setModalEliminar]= useState(false);
  const [duplicate, setDuplicate]= useState(false);
  const [loading, setLoading]= useState(true);
  const [hotelSeleccionado, sethotelSeleccionado]=useState({
    ht_id:'',
    ht_name:'',
    ht_address:'',
    ht_city:'',
    ht_nit:'',
    ht_nrooms:'',
    ht_dreg:''
  });
  const [detalleSeleccionado, setDetalleSeleccionado]=useState({
    fk_hotel_ht_id:'',
    htdt_acco:'',
    htdt_amount:'',
    htdt_id:'',
    htdt_type:''
  });
  const [acomodaciones, setAcomodaciones]=useState([]);

  const handleChange=e=>{
    const {name, value}=e.target;
    if (name === "htdt_type" || name === "htdt_acco" || name === "htdt_amount") {
      setDetalleSeleccionado((prevState)=>({
        ...prevState,
        [name]:value
      }))
    }else{
      sethotelSeleccionado((prevState)=>({
        ...prevState,
        [name]:value
      }))
    }
      if (name === "htdt_type") {
        if (value === "Estándar") {
          setAcomodaciones(["Sencilla","Doble"]);
        }else if (value === "Junior") {
          setAcomodaciones(["Triple","Cuádruple"]);
        }else if (value === "Suite") {
          setAcomodaciones(["Sencilla","Doble","Triple"]);
        }else {
          setAcomodaciones([]);
        }
      }
  }

  const abrirCerrarModalInsertar=()=>{
    setModalInsertar(!modalInsertar);
  }
  const abrirCerrarModalHabitaciones=()=>{
    setModalHabitaciones(!modalInsertarHabitaciones);
  }
  const abrirCerrarModalEditar=()=>{
    setModalEditar(!modalEditar);
  }
  const abrirCerrarModalEliminar=()=>{
    setModalEliminar(!modalEliminar);
  }

  const peticionGet=async()=>{
    await axios.get(baseUrl)
    .then(response=>{
      setData(response.data);
      setLoading(false)
    }).catch(error=>{
      console.log(error);
    })
  }
  const Act_consulta=()=>{
    axios.get(baseUrl)
    .then(response=>{
      setData(response.data);
    }).catch(error=>{
      console.log(error);
    })
  }
  const consultarHabitaciones=(hotelid)=>{
    axios.get(baseUrl+"?htdt="+hotelid)
    .then(response=>{
      setDataDetalle(response.data);
    }).catch(error=>{
      console.log(error);
    })
  }
  const validarDuplicado=()=>{
    fetch(baseUrl+'?nameValidate='+hotelSeleccionado.ht_name+'&nitValidate='+hotelSeleccionado.ht_nit)
    .then(respuesta=>respuesta.json())
    .then((datosRespuesta)=>{
        console.log('datosRespuesta1 len')
        console.log(datosRespuesta.length);
        if (datosRespuesta.length > 0) {
          setDuplicate(true);
        }else{
          setDuplicate(false);
        }
    }).catch(error=>{
      console.log(error);
    })
  }
//Registrar hotel
  const enviarDatos = (e) =>{
    setLoading(true);
    e.preventDefault();
    validarDuplicado();
    if (duplicate === false) {
      var datosEnviar={
        ht_name:hotelSeleccionado.ht_name,
        ht_address:hotelSeleccionado.ht_address,
        ht_city:hotelSeleccionado.ht_city,
        ht_nit:hotelSeleccionado.ht_nit,
        ht_nrooms:hotelSeleccionado.ht_nrooms
      }
      fetch(baseUrl+"?METHOD=POST",{
          method:"POST",
          body:JSON.stringify(datosEnviar)
      })
      .then(respuesta=>respuesta.json())
      .then((datosRespuesta)=>{
          console.log(datosRespuesta);
          Act_consulta();
      }).catch(error=>{
        console.log(error);
      }) 
    }
    Act_consulta();
    setLoading(false);
    abrirCerrarModalInsertar();
  }
//Registrar detalle
const enviarDatosDetalle = (e) =>{
  e.preventDefault();
  var datosEnviar={
    ht_address:detalleSeleccionado.htdt_type,
    ht_city:detalleSeleccionado.htdt_acco,
    ht_nit:detalleSeleccionado.htdt_amount,
    ht_nrooms:hotelSeleccionado.ht_id,
  }
  fetch(baseUrl+"?METHOD=POSTDT",{
      method:"POST",
      body:JSON.stringify(datosEnviar)
  })
  .then(respuesta=>respuesta.json())
  .then((datosRespuesta)=>{
      console.log(datosRespuesta);
      consultarHabitaciones(hotelSeleccionado.ht_id);
  }).catch(error=>{
    console.log(error);
  }) 
}
//Actualizar hotel
  const actualizarDatos = (e) =>{
    e.preventDefault();
    if (duplicate === false) {
      var datosActualizar={
        ht_id:hotelSeleccionado.ht_id,
        ht_name:hotelSeleccionado.ht_name,
        ht_address:hotelSeleccionado.ht_address,
        ht_city:hotelSeleccionado.ht_city,
        ht_nit:hotelSeleccionado.ht_nit,
        ht_nrooms:hotelSeleccionado.ht_nrooms
      }
      console.log(datosActualizar);
      fetch(baseUrl+"?METHOD=PUT",{
          method:"POST",
          body:JSON.stringify(datosActualizar)
      })
      .then(respuesta=>respuesta.json())
      .catch(error=>{
        console.log(error);
      }) 
    }
    Act_consulta();
    abrirCerrarModalEditar();
  }
//Eliminar hotel
  const peticionDelete=async()=>{
    setLoading(true);
    var f = new FormData();
    f.append("METHOD", "DELETE");
    await axios.post(baseUrl, f, {params: {ht_id: hotelSeleccionado.ht_id}})
    .then(response=>{
      setData(data.filter(hotel=>hotel.ht_id!==hotelSeleccionado.ht_id));
      setLoading(false);
      abrirCerrarModalEliminar();
    }).catch(error=>{
      console.log(error);
    })
  }
//Seleccionar hotel
  const seleccionarhotel=(hotel, caso)=>{
    sethotelSeleccionado(hotel);

    if (caso==="Editar") {
      abrirCerrarModalEditar()
    }else if (caso==="Eliminar") {
      abrirCerrarModalEliminar()
    }else if (caso==="Habitaciones") {
      consultarHabitaciones(hotel.ht_id);
      abrirCerrarModalHabitaciones()
    }    
  }
//Eliminar Detalle
  const eliminarDetalle=(detalle, caso)=>{
    var f = new FormData();
    f.append("METHOD", "DELETEDETALLE");
    console.log(baseUrl, f, {params: {htdt_id: detalle.htdt_id}})
    axios.post(baseUrl, f, {params: {htdt_id: detalle.htdt_id}})
    .then(response=>{
      console.log('Eliminado');
      consultarHabitaciones(detalle.fk_hotel_ht_id);
    }).catch(error=>{
      console.log(error);
    })
  }

  useEffect(()=>{
    peticionGet();
  },[])

  if (loading) {
    return (
      <div style={{textAlign: 'center'}}>
        <Spinner color="success"/>
      </div>
    );
  }else{
    return (
      
      <div style={{textAlign: 'center'}}>      
        
        
        <div style={{background: '#014b9e', height: '100px'}} >
         <h1 style={{color:'#FFF'}}>HOTELES DECAMERON</h1>
        </div>
        <br/>
        {duplicate==true && <div className="alert alert-danger alert-dismissible fade show" role="alert">
        <strong>Registro fallido</strong> Ya existe un hotel con el mismo nombre!
          <button type="button" className="btn-close" onClick={()=>setDuplicate(false)} data-bs-dismiss="alert" aria-label="Close"></button>
        </div>}
        <br />
        <button className="btn btn-success" onClick={()=>abrirCerrarModalInsertar()}>Insertar Hotel</button>
        <br /><br />
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Nombre</th>
              <th>Dirección</th>
              <th>Ciudad</th>
              <th>NIT</th>
              <th>N° de Habitaciones</th>
              <th>Fecha Reg</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {data.map(hotel=>(
              <tr key={hotel.ht_id}>
                <td>{hotel.ht_name}</td>
                <td>{hotel.ht_address}</td>
                <td>{hotel.ht_city}</td>
                <td>{hotel.ht_nit}</td>
                <td>{hotel.ht_nrooms}</td>
                <td>{hotel.ht_dreg}</td>
              <td>
              <button className="btn btn-success" onClick={()=>seleccionarhotel(hotel, "Habitaciones")}>
                Habitaciones</button> {"  "}
              <button className="btn btn-primary" onClick={()=>seleccionarhotel(hotel, "Editar")}>Editar</button> {"  "}
              <button className="btn btn-danger" onClick={()=>seleccionarhotel(hotel, "Eliminar")}>Eliminar</button>
              </td>
              </tr>
            ))}
          </tbody>
        </table>

        <Modal className="modal-lg" isOpen={modalInsertar}>
          <ModalHeader>Insertar hotel</ModalHeader>
          <ModalBody>
            <form onSubmit={enviarDatos}>
            <div className="form-group">
              <div className="row">
                  <div className="form-group col-md-6">
                      <label>Nombre: </label>
                      <input type="text" name="ht_name"  onChange={handleChange} className="form-control"  required/>
                  </div>
                  <div className="form-group col-md-6">
                      <label>Dirección: </label>
                      <input type="text" name="ht_address"  onChange={handleChange} className="form-control" required/>
                  </div>
              </div><br></br>
              <div className="row">
                  <div className="form-group col-md-4">
                      <label>Ciudad: </label>
                      <input type="text" name="ht_city"  onChange={handleChange} className="form-control" required />
                  </div>
                  <div className="form-group col-md-4">
                      <label>NIT: </label>
                      <input type="text" name="ht_nit"  onChange={handleChange} className="form-control" pattern='[0-9/-]{1,25}' required />
                  </div>                          
                  <div className="form-group col-md-4">
                      <label>No. de Habitaciones: </label>
                      <input type="text" name="ht_nrooms"  onChange={handleChange} className="form-control" pattern='[0-9]{1,3}' required />
                  </div>
              </div><br></br>
            </div>
              <button type="submit" className="btn btn-success">Guardar</button>{"  "}
              <a className="btn btn-danger" onClick={()=>abrirCerrarModalInsertar()}>Cancelar</a>
            </form>
          </ModalBody>
          <ModalFooter>
          
          </ModalFooter>
        </Modal>
        <Modal className="modal-lg" isOpen={modalInsertarHabitaciones}>
          <ModalHeader>Habitaciones {hotelSeleccionado && hotelSeleccionado.ht_name}</ModalHeader>
          <ModalBody>
            <form onSubmit={enviarDatosDetalle}>
            <div className="form-group">
              <div className="row">
                  <div className="form-group col-md-3">
                      <label>Tipo: </label>
                      <select name="htdt_type" onChange={handleChange} className="form-select" required>
                        <option value="">Seleccione</option>
                        <option value="Estándar">Estándar</option>
                        <option value="Junior">Junior</option>
                        <option value="Suite">Suite</option>
                      </select>
                  </div>
                  <div className="form-group col-md-3">
                      <label>Acomodación: </label>
                      <select name="htdt_acco"  onChange={handleChange} className="form-select" required>
                        <option value="">Seleccione</option>
                        {acomodaciones.map(acco=>(
                          <option key={acco} value={acco}>{acco}</option>
                        ))}
                      </select>
                  </div>
                  <div className="form-group col-md-4">
                      <label>Cantidad: </label>
                      <input type="text" name="htdt_amount"  onChange={handleChange} className="form-control" required/>
                  </div>
              </div><br></br>
            </div>
              <button type="submit" className="btn btn-success">Guardar</button>{"  "}
              <a className="btn btn-danger" onClick={()=>abrirCerrarModalHabitaciones()}>Cancelar</a>
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>Tipo</th>
                    <th>Acomodación</th>
                    <th>Cantidad</th>
                    <th>Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  {dataDetalle.map(detalle=>(
                    <tr key={detalle.htdt_id}>
                      <td>{detalle.htdt_type}</td>
                      <td>{detalle.htdt_acco}</td>
                      <td>{detalle.htdt_amount}</td>
                    <td>
                    <button type='button' className="btn btn-danger" onClick={()=>eliminarDetalle(detalle, "Eliminar")}>Eliminar</button>
                    </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </form>
          </ModalBody>
          <ModalFooter>
          
          </ModalFooter>
        </Modal>
        <Modal className="modal-lg" isOpen={modalEditar}>
          <ModalHeader>Editar hotel</ModalHeader>
          <ModalBody>
            <form onSubmit={actualizarDatos}>
              <div className="form-group">
                <div className="row">
                    <div className="form-group col-md-6">
                        <label>Nombre: </label>
                        <input type="text" name="ht_name" onChange={handleChange} className="form-control"  value={hotelSeleccionado && hotelSeleccionado.ht_name}  required/>
                    </div>
                    <div className="form-group col-md-6">
                        <label>Dirección: </label>
                        <input type="text" name="ht_address" onChange={handleChange} className="form-control"  value={hotelSeleccionado && hotelSeleccionado.ht_address} required />
                    </div>
                </div><br></br>
                <div className="row">                                            
                    <div className="form-group col-md-4">
                        <label>Ciudad: </label>
                        <input type="text" name="ht_city" onChange={handleChange} className="form-control"  value={hotelSeleccionado && hotelSeleccionado.ht_city} required/>
                    </div>                                 
                    <div className="form-group col-md-4">
                        <label>NIT: </label>
                        <input type="text" name="ht_nit" onChange={handleChange} className="form-control"  value={hotelSeleccionado && hotelSeleccionado.ht_nit} pattern='[0-9/-]{1,25}' required/>
                    </div>
                    <div className="form-group col-md-4">
                        <label>No. Habitaciones: </label>
                        <input type="text" name="ht_nrooms" onChange={handleChange} className="form-control"  value={hotelSeleccionado && hotelSeleccionado.ht_nrooms} pattern='[0-9]{1,3}' required/>
                    </div>     
                </div><br></br>
                <button type="submit" className="btn btn-success">Editar</button>{"  "}
                <a className="btn btn-danger" onClick={()=>abrirCerrarModalEditar()}>Cancelar</a>
              </div>
            </form>
          </ModalBody>
          <ModalFooter>
            
          </ModalFooter>
        </Modal>
        <Modal isOpen={modalEliminar}>
          <ModalBody>
          ¿Estás seguro que deseas eliminar el hotel {hotelSeleccionado && hotelSeleccionado.ht_name}?
          </ModalBody>
          <ModalFooter>
            <button className="btn btn-danger" onClick={()=>peticionDelete()}>
              Sí
            </button>
            <button
              className="btn btn-secondary"
              onClick={()=>abrirCerrarModalEliminar()}
            >
              No
            </button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default App;